import React from "react";
import { useNavigate } from "react-router-dom";

import closeIcon from "../../assets/Close.svg";
import SuccessImage from "../../assets/success.png";
import "./ThankYouSignUp.scss";

const ThankYouSignUp = ({
  isOpened,
  image,
  title,
  EmailCheckText,
  onClose,
}) => {
  const navigate = useNavigate();

  const handleSignUpOk = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return isOpened ? (
    <div className="container">
      <div className="ThankYouSignUp-cont" onClick={onClose}>
        <div
          className="ThankYouSignUp-cont__content"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={closeIcon}
            alt="closeIcon"
            className="ThankYouSignUp-cont__content__imgClose"
            onClick={onClose}
          />

          <div className="ThankYouSignUp-cont__content__imgWrapper">
            <img
              src={image ? image : SuccessImage}
              alt="heart"
              className="ThankYouSignUp-cont__content__imgWrapper__img"
            />
          </div>
          <div className="ThankYouSignUp-cont__content__textCont">
            {title && (
              <p className="ThankYouSignUp-cont__content__textCont__firstText">
                {title}
              </p>
            )}
            {EmailCheckText && (
              <div className="ThankYouSignUp-cont__content__textCont__SecondText">
                {EmailCheckText}
              </div>
            )}
          </div>
          <button
            className="ThankYouSignUp-cont__btnCont"
            onClick={(e) => handleSignUpOk(e)}
          >
            <p className="ThankYouSignUp-cont__btnCont__text">OK</p>
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ThankYouSignUp;
